import { pushEvent } from "./event";
import { getDuration } from "./language";

// Modals and UI related code

const $ = (id) => document.getElementById(id);
const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

let isOpen = false;
let currentModal = null;

export const isModalOpen = () => isOpen;
export const getModalName = () => currentModal;

const modalBackground = $("modal-bg");
const modalContainer = $("modal-container");
const modalPanel = $("modal-panel");

let modalContent;

export const openModal = async (modalName) => {
  if (isOpen || currentModal) {
    await closeModal();
  }

  if (modalName === "statistics") {
    createStatistics();
  }

  modalContent = $(`modal-${modalName}`);

  isOpen = true;
  currentModal = modalName;

  // Make the container and content visible
  modalContainer.style.display = "block";
  modalContent.style.display = "block";

  // Fade in the background overlay
  modalBackground.classList.add("ease-out", "duration-300", "opacity-0");
  setTimeout(() => {
    modalBackground.classList.add("opacity-100");
    modalBackground.classList.remove("opacity-0");
  });

  // Animate in the modal itself
  modalPanel.classList.add(
    "ease-in",
    "duration-300",
    "opacity-0",
    "translate-y-4",
    "sm:translate-y-0",
    "sm:scale-95"
  );
  setTimeout(() => {
    modalPanel.classList.add("opacity-100", "translate-y-0", "sm:scale-100");
    modalPanel.classList.remove(
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
  });
};

document.querySelectorAll("[data-open-modal]").forEach((el) => {
  el.addEventListener("click", async () => {
    if (isOpen) {
      await closeModal();
    }
    const modalName = el.dataset.openModal;
    if (modalName === "help") {
      pushEvent("Help");
    }
    if (modalName === "statistics") {
      pushEvent("Statistics");
    }
    openModal(modalName);
    el.blur();
  });
});

export const closeModal = async () => {
  if (!window.gameLoaded) {
    document.querySelector("#board .js-info")?.classList.remove("hidden");
    document.querySelector("#board .js-error")?.classList.add("hidden");
  }

  if (currentModal === "help") {
    localStorage["iknowhowtoplay"] = "yes";
  }

  // Fade out the background
  modalBackground.classList.add("ease-in", "duration-200", "opacity-100");
  setTimeout(() => {
    modalBackground.classList.add("opacity-0");
    modalBackground.classList.remove("opacity-100");
  });

  modalPanel.classList.add(
    "ease-in",
    "duration-200",
    "opacity-100",
    "translate-y-0",
    "sm:scale-100"
  );
  setTimeout(() => {
    modalPanel.classList.add(
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
    modalPanel.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
  });

  await sleep(200);

  modalContainer.style.display = "none";
  if (modalContent) {
    modalContent.style.display = "none";
  }
  isOpen = false;
  currentModal = null;
};

const collectStatistics = () => {
  const statistics = {
    total: 0,
    solved: 0,
    time: 0,
    dist: [],
  };
  const distributions = {};

  let gameSlug = null;
  if ($("settings")) {
    const settings = JSON.parse($("settings").innerText);
    if (settings.slug) {
      gameSlug = settings.slug;
    }
  }

  // Iterate over all localStorage keys
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    try {
      const game = JSON.parse(localStorage[key]);
      if (gameSlug && gameSlug !== game.game) {
        continue;
      }
      statistics.total++;

      if (game.status === "solved") {
        statistics.solved++;

        // Calculate time running average
        const time = game.stats.end - game.stats.start;
        statistics.time =
          (statistics.time * (statistics.solved - 1) + time) /
          statistics.solved;

        // Add to distribution
        if (!distributions[game.currentRow]) {
          distributions[game.currentRow] = 0;
        }
        distributions[game.currentRow]++;
      }
    } catch (e) {}
  }

  for (let i = 1; i <= Math.max(...Object.keys(distributions)); i++) {
    statistics.dist.push(distributions[i] || 0);
  }

  return statistics;
};

const createStatistics = () => {
  const data = collectStatistics();

  $("stats-total").innerText = data.total;
  $("stats-solved").innerText = data.solved;
  $("stats-solved-percent").innerText = data.total
    ? `(${Math.round((data.solved / data.total) * 100)}%)`
    : "";

  let duration = getDuration(data.time, true).trimEnd();
  const durationUnit = duration.split(":").length;
  if (durationUnit === 1) {
    duration += "s";
  } else if (durationUnit === 2) {
    duration += "m";
  } else {
    duration += "h";
  }
  $("stats-time").innerText = duration;

  const distributionEl = $("stats-dist");
  const distributionMax = Math.max(...data.dist);
  distributionEl.innerHTML = "";

  data.dist.forEach((count, i) => {
    const el = document.createElement("div");
    const absolutePercent = Math.round((count / data.solved) * 100);
    const relativePercentage = Math.round((count / distributionMax) * 100);
    el.classList.add("flex", "items-center");
    el.innerHTML = `
      <dt class="font-semibold dark:text-gray-200 w-4 mr-2">${i + 1}</dt>
    `;
    if (count === 0) {
      el.innerHTML += `
        <dd class="text-center p-1 text-gray-900 dark:text-gray-100">0</dd>
      `;
    } else {
      el.innerHTML += `
        <dd class="bg-gray-500 text-white rounded flex ${
          absolutePercent > 5 ? "justify-between" : "justify-end"
        } items-center p-1 px-2 min-w-min"
            style="width: ${relativePercentage}%;">
          ${absolutePercent > 5 ? `<small>${absolutePercent}%</small>` : ""}
          <span>${count}</span>
        </dd>
      `;
    }
    distributionEl.appendChild(el);
  });
};

setTimeout(async () => {
  // Check if hide sidebar cookie is set
  if (!document.cookie.includes("aside=hidden")) {
    $("sidebar")?.classList.add("opacity-100");
    $("sidebar")?.classList.remove("pointer-events-none");
    $("sidebar-close")?.addEventListener("click", () => {
      // Remember hiding the sidebar for a week in a cookie
      const now = new Date();
      now.setDate(now.getDate() + 7);
      document.cookie = `aside=hidden; expires=${now.toUTCString()}`;
      $("sidebar").classList.remove("duration-700", "opacity-100");
      $("sidebar")?.classList.add("pointer-events-none");
    });
    await sleep(3500);
    $("sidebar-close")?.classList.add("opacity-100");
  } else {
    console.info("not showing sidebar");
  }
}, 10000);

document.querySelectorAll("[data-modal-close]").forEach((el) => {
  el.addEventListener("click", () => {
    closeModal();
  });
});
