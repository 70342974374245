const $ = (id) => document.getElementById(id);

const themeButton = $("toggle-theme");
const setTheme = (theme) => {
  if (!theme) {
    // Toggle
    theme = document.documentElement.classList.contains("dark")
      ? "light"
      : "dark";
  }
  if (theme === "light") {
    localStorage.theme = "light";
    document.documentElement.classList.remove("dark");
    document.querySelector('meta[name="theme-color"]').content = "#f1f5f9";
  } else {
    localStorage.theme = "dark";
    document.documentElement.classList.add("dark");
    document.querySelector('meta[name="theme-color"]').content = "#1e293b";
  }
};

if (themeButton) {
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    setTheme("dark");
  } else {
    setTheme("light");
  }
  themeButton.addEventListener("click", () => setTheme());
}

const handleToggle = (buttonEl, identifier, callback) => {
  const toggle = () => {
    // Toggle the value
    const enabled = localStorage[identifier] !== "true";
    localStorage[identifier] = enabled ? "true" : "false";

    if (enabled) {
      buttonEl.classList.add("!bg-green-600");
      buttonEl.children[0].classList.add("!translate-x-5");
    } else {
      buttonEl.classList.remove("!bg-green-600");
      buttonEl.children[0].classList.remove("!translate-x-5");
    }
    callback(enabled);
  };

  if (localStorage[identifier] === "true") {
    callback(true);
    buttonEl.classList.add("!bg-green-600");
    buttonEl.children[0].classList.add("!translate-x-5");
  } else {
    callback(false);
  }
  buttonEl.addEventListener("click", () => toggle());
};

const colorblindButton = $("toggle-colorblind");
handleToggle(colorblindButton, "colorblind", (enabled) => {
  if (enabled) {
    document.documentElement.classList.add("colorblind");
  } else {
    document.documentElement.classList.remove("colorblind");
  }
});

let hardmode = false;
export const isHardMode = () => hardmode;

const hardmodeButton = $("toggle-hardmode");
handleToggle(hardmodeButton, "hardmode", (enabled) => {
  hardmode = enabled;
});
