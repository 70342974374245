const qwerty = [
  ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  ["", "a", "s", "d", "f", "g", "h", "j", "k", "l", ""],
  ["enter", "z", "x", "c", "v", "b", "n", "m", "delete"],
];

export const keyboardLayouts = {
  default: {
    pattern: "^[a-zA-Z]*$",
    layout: qwerty,
    hasDictionary: true,
  },
  numbers: {
    pattern: "^[a-zA-Z0-9]*$",
    layout: [["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], ...qwerty],
  },
  german: {
    pattern: "^[a-zA-ZÄäÖöÜüß]*$",
    layout: [
      ["q", "w", "e", "r", "t", "z", "u", "i", "o", "p", "ü", "ß"],
      [...qwerty[1].slice(0, 10), "ö", "ä"],
      ["enter", "y", ...qwerty[2].slice(2)],
    ],
    hasDictionary: true,
  },
  french: {
    pattern: "^[a-zA-ZÉéÊêÈèÇçÀàÂâÙùŒœ]*$",
    layout: [
      [" ", "é", "ê", " ", " ", " ", "è", "œ", "ç", "à"],
      ["a", "z", "e", "r", "t", "y", "u", "i", "o", "p", "â"],
      ["", "q", "s", "d", "f", "g", "h", "j", "k", "l", "m", "ù"],
      ["enter", "", "w", "x", "c", "v", "b", "n", "", "delete"],
    ],
    hasDictionary: true,
  },
  italian: {
    pattern: "^[a-zA-ZÒòÇçÀàÈèÉéÙù]*$",
    layout: [["", " ", " ", " ", "ò", "ç", "à", "è", "é", "ù", ""], ...qwerty],
    hasDictionary: true,
  },
  polish: {
    pattern: "^[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż]*$",
    layout: [["", "ą", "ć", "ę", "ł", "ń", "ó", "ś", "ż", "ź", ""], ...qwerty],
    hasDictionary: true,
  },
  dutch: {
    pattern: "^[a-zA-Z]*$",
    layout: [["", " ", "é", "ë", " ", " ", "ü", "ï", "ó", "ö", ""], ...qwerty],
  },
  spanish: {
    pattern: "^[a-zA-ZÁáÉéÓóÚúÜüÍíÑñ]*$",
    layout: [
      ["", " ", "á", "é", " ", "ü", "ú", "í", "ó", " ", " "],
      [...qwerty[0], ""],
      [...qwerty[1].slice(0, 10), "ñ"],
      qwerty[2],
    ],
    hasDictionary: true,
  },
  lakota: {
    pattern: "^[AÁBČEÉGǦHȞIÍKLMNŊOÓPSŠTUÚWYZŽaábčeégǧhȟiíklmnŋoópsštuúwyzž]*$",
    layout: [
      ["", " ", " ", "á", "é", "í", "ó", "ú", " ", " ", ""],
      ["ǧ", "w", "e", "š", "t", "y", "u", "i", "o", "p"],
      ["", "a", "s", "d", "ŋ", "g", "h", "ȟ", "k", "l", ""],
      ["enter", "z", "ž", "č", "v", "b", "n", "m", "delete"],
    ],
  },
};

// Aliases for old links
keyboardLayouts.qwerty = keyboardLayouts.default;
keyboardLayouts.qwertynum = keyboardLayouts.numbers;
keyboardLayouts.qwertz = keyboardLayouts.german;
keyboardLayouts.azerty = keyboardLayouts.french;

const i18n = {
  en: {
    // Create form
    err_nospaces: "Sorry, no spaces.",
    err_pattern:
      "You can't enter this on the keyboard, try changing the language?",
    err_short: "Your word should at least have 3 characters.",
    err_long: "That's a long puzzle, it won't be very fun.",
    err_dupe: "Try to keep duplicate letters to a minimum.",
    err_rand: "You're just mashing your keyboard aren't you?",
    good_puzzle: "That looks like a good puzzle!",
    guess_help: "How many tries will you allow?",
    guess_many: "Oh, feeling generous today.",
    guess_few: "Extra hard, I like it.",
    // Dictionary
    dict_loading: "Dictionary is loading... ⌛",
    dict_ready: "Dictionary is ready. 📖",
    dict_error: "I don't know that word. 🤓",
    // Toast popups
    toast_solved: "Yes! You got it! 🥳",
    toast_failed: "You didn't find the solution 😢",
    toast_failed_again: "That wasn't the solution either 😭",
    toast_nodict:
      "❌ No dictionary for this language (yet), please disable the checkbox.",
    toast_extralife: "Unlocked an extra life! 🍄",
    toast_share_copied: "Copied result to clipboard! 📋",
    toast_too_short: "That's too short, try again. 🧐",
    // Hardmode
    hardmore_err_correct: "You must use all correct letters 😶",
    hardmore_err_present: "Your guess must include VAR 😶",
    // Results
    seconds: ["one second", "NUM seconds"],
    minutes: "minutes",
    hours: "hours",
    deleted0_solved:
      "You went straight for the answer and never once deleted a letter!",
    deleted0_failed:
      "You entered some random words and never corrected a letter!",
    deleted_letters: [
      "While puzzling, you only fixed one single typo.",
      "While puzzling, you changed your mind <b>NUM</b> times by changing a letter",
    ],
    deleted0_invalid0: " And you never even tried to enter an invalid word.",
    invalid_words: [
      " and tried to enter <b>1</b> word that doesn't really exist.",
      " and tried to enter <b>NUM</b> words that don't really exist.",
    ],
    puzzle_solved: "I solved this PUZZLE_NAME with VAR attempts ",
    puzzle_duration: "in VAR. Can you do better?",
    puzzle_failed: "I couldn't solve this PUZZLE_NAME in VAR guesses. Can you?",
    share_text: "Can you guess my word?",
    share_copy: "Copy Link",
    share_copied: "Copied!",
    copy_result: 'Copy <span class="hidden sm:inline">Result</span>',
    err_highscore: "Error submitting highscore. 😶",
  },
  fr: {
    // Créer un formulaire
    err_nospaces: "Désolé, pas d'espaces",
    err_pattern:
      "Tu ne peux pas saisir ce mot au clavier, essaie de changer de langue?",
    err_short: "Ton mot doit comporter au moins 3 caractères.",
    err_long: "C'est une longue énigme, ce ne sera pas très amusant.",
    err_dupe: "Essaie de limiter au maximum les lettres en double.",
    err_rand: "Tu es en train de taper sur ton clavier, n'est-ce pas?",
    good_puzzle: "Cela ressemble à un bon puzzle!",
    guess_help: "Combien d'essais vas-tu autoriser?",
    guess_many: "Oh, je me sens généreux aujourd'hui.",
    guess_few: "Très difficile, j'aime ça.",
    // Dictionnaire
    dict_loading: "Le dictionnaire est en train de se charger... ⌛",
    dict_ready: "Le dictionnaire est prêt. 📖",
    dict_error: "Je ne connais pas ce mot. 🤓",
    // Popups de toast
    toast_solved: "Oui! Tu l'as trouvé! 🥳",
    toast_failed: "Tu n'as pas trouvé la solution 😢",
    toast_failed_again: "Ce n'était pas non plus la solution 😭",
    toast_nodict:
      "❌ Pas de dictionnaire pour cette langue, désactive la case à cocher.",
    toast_extralife: "Tu as débloqué une vie supplémentaire! 🍄",
    toast_share_copied: "Copie le résultat dans le presse-papiers! 💖",
    toast_too_short: "C'est trop court, essaye encore. 🧐",
    // Hardmode
    hardmore_err_correct: "Tu dois utiliser toutes les lettres correctes 😶",
    hardmore_err_present: "Ton essai doit contenir VAR 😶",
    // Résultats
    seconds: ["une seconde", "NUM secondes"],
    minutes: "minutes",
    hours: "heures",
    deleted0_solved:
      "Tu es allé directement à la réponse et tu n'as jamais supprimé une seule lettre!",
    deleted0_failed:
      "Tu as saisi des mots au hasard et tu n'as jamais corrigé une lettre!",
    deleted_letters: [
      "En faisant une énigme, tu n'as corrigé qu'une seule faute de frappe",
      "Pendant le puzzle, tu as changé d'avis <b>NUM</b> fois en modifiant une lettre",
    ],
    deleted0_invalid0: " Et tu n'as même pas essayé de saisir un mot invalide.",
    invalid_words: [
      " et a essayé d'entrer <b>1</b> mot qui n'existe pas vraiment.",
      " et a essayé de saisir <b>NUM</b> mots qui n'existent pas vraiment.",
    ],
    puzzle_solved: "J'ai résolu ce PUZZLE_NAME avec des VAR tentatives ",
    puzzle_duration: "en VAR. Peux-tu faire mieux?",
    puzzle_failed:
      "Je n'ai pas réussi à résoudre ce PUZZLE_NAME avec des VAR tentatives. Le peux-tu?",
    share_text: "Peux-tu deviner mon mot?",
    share_copy: "Copier le lien",
    share_copied: "Copié!",
    copy_result: 'Copier <span class="hidden sm:inline">Résultat</span>',
    err_highscore: "Erreur de soumission de highscore. 😶",
  },
  de: {
    // Create form
    err_nospaces: "Ups, keine Leerschläge erlaubt.",
    err_pattern:
      "Das kann nicht auf der Tastatur eingegeben werden. Möchtest du die Sprache wechseln?",
    err_short: "Dein Wort sollte mindestens 3 Buchstaben lang sein.",
    err_long: "Das Puzzle macht weniger Spass wenn's zu lang wird.",
    err_dupe: "Versuche weniger doppelte Buchstaben zu benutzen.",
    err_rand: "Läuft deine Katze über die Tastatur?",
    good_puzzle: "Das sieht nach einem guten Rätsel aus!",
    guess_help: "Wie viele Versuche lässt du zu?",
    guess_many: "Soso, heute sind wir grosszügig.",
    guess_few: "Extra schwer, so mag ich es.",
    // Dictionary
    dict_loading: "Wörterbuch lädt noch... ⌛",
    dict_ready: "Wörterbuch ist bereit. 📖",
    dict_error: "Das Wort kenne ich nicht. 🤓",
    // Toast popups
    toast_solved: "Jup! Das ist die Lösung! 🥳",
    toast_failed: "Du hast das Wort nicht herausgefunden 😢",
    toast_failed_again: "Das war auch nicht die Lösung 😭",
    toast_nodict:
      "❌ Kein Wörterbuch für diese Sprache, bitte Checkbox abwählen.",
    toast_extralife: "Extra Leben freigeschaltet! 🍄",
    toast_share_copied: "Resultat in die Zwischenablage kopiert! 💖",
    toast_too_short: "Das ist zu kurz, versuche es nochmal. 🧐",
    // Hardmode
    hardmore_err_correct: "Du musst alle richtigen Buchstaben verwenden 😶",
    hardmore_err_present: "Dein Versuch muss VAR enthalten 😶",
    // Results
    seconds: ["eine Sekunde", "NUM Sekunden"],
    minutes: "Minuten",
    hours: "Stunden",
    deleted0_solved:
      "Du hast direkt deine Antwort eingegeben und nie einen Buchstaben korrigiert!",
    deleted0_failed:
      "Du hast einfach drauf los getippt und nicht mal einen Buchstaben gelöscht!",
    deleted_letters: [
      "Beim Lösen hast du ein einziges Mal einen Tippfehler korrigiert",
      "Beim Lösen hast du <b>NUM</b> Mal einige Buchstaben gelöscht",
    ],
    deleted0_invalid0:
      " Und du hast nie versucht ein ungültiges Wort einzugeben.",
    invalid_words: [
      " und <b>1</b> Wort versucht, das es gar nicht gibt.",
      " und <b>NUM</b> Wörter eingetippt, die es gar nicht gibt.",
    ],
    puzzle_solved: "Ich habe dieses PUZZLE_NAME mit VAR Versuchen gelöst ",
    puzzle_duration: "und VAR gebraucht. Kannst du's besser?",
    puzzle_failed:
      "Ich konnte dieses PUZZLE_NAME in VAR Versuchen nicht lösen. Kannst du es?",
    share_text: "Findest du mein Wort heraus?",
    share_copy: "Link kopieren",
    share_copied: "Kopiert!",
    copy_result: '<span class="hidden sm:inline">Resultat</span> kopieren',
    err_highscore: "Fehler beim Übertragen der Highscore. 😶",
  },
  es: {
    // Create form
    err_nospaces: "Lo siento, no hay espacios.",
    err_pattern:
      "No puedes introducir esto en el teclado, prueba a cambiar el idioma...",
    err_short: "Su palabra debe tener al menos 3 caracteres.",
    err_long: "Es un rompecabezas muy largo, no será muy divertido.",
    err_dupe: "Intente reducir al mínimo la duplicación de cartas.",
    err_rand: "Sólo estás machacando el teclado, ¿no?",
    good_puzzle: "¡Parece un buen puzzle!",
    guess_help: "¿Cuántos intentos permitirá?",
    guess_many: "Oh, hoy me siento generoso.",
    guess_few: "Extra duro, me gusta.",
    // Dictionary
    dict_loading: "El diccionario se carga... ⌛",
    dict_ready: "El diccionario está preparado. 📖",
    dict_error: "No conozco esa palabra. 🤓",
    // Toast popups
    toast_solved: "¡Sí, lo tienes! 🥳",
    toast_failed: "No has encontrado la solución 😢",
    toast_failed_again: "Esa tampoco era la solución 😭",
    toast_nodict:
      "❌ No hay diccionario para este idioma (todavía), por favor desactive la casilla.",
    toast_extralife: "¡Desbloqueado una vida extra! 🍄",
    toast_share_copied: "Resultado copiado en el portapapeles. 💖",
    toast_too_short: "¡Esa es demasiado corta, intenta otra vez! 🧐",
    // Hardmode
    hardmore_err_correct: "Debes utilizar todas las letras correctas 😶",
    hardmore_err_present: "Tu intento debe contener VAR 😶",
    // Results
    seconds: ["un segundo", "NUM segundos"],
    minutes: "minutos",
    hours: "horas",
    deleted0_solved:
      "Has ido directamente a por la respuesta y no has borrado ni una sola letra.",
    deleted0_failed:
      "Has introducido algunas palabras al azar y no has corregido ninguna letra.",
    deleted_letters: [
      "Aunque es desconcertante, sólo ha corregido una única errata.",
      "Mientras se desconcierta, se cambia de opinión <b>NUM</b> veces cambiando una letra",
    ],
    deleted0_invalid0:
      " Y ni siquiera intentaste introducir una palabra inválida.",
    invalid_words: [
      " y trató de introducir <b>1</b> palabra que realmente no existe.",
      " y trató de introducir <b>NUM</b> palabras que realmente no existen.",
    ],
    puzzle_solved: "He resuelto esta PUZZLE_NAME con VAR intentos ",
    puzzle_duration: "en VAR. ¿Puedes hacerlo mejor?",
    puzzle_failed:
      "No he podido resolver esta PUZZLE_NAME en VAR intentos. ¿Puede usted?",
    share_text: "¿Puedes adivinar mi palabra?",
    share_copy: "Copiar enlace",
    share_copied: "¡Copiado!",
    copy_result: 'Copiar <span class="hidden sm:inline">Resultado</span>',
    err_highscore: "Error al enviar la puntuación máxima. 😶",
  },
};

export const LANG = document.documentElement.lang;

// Translate text
export const _ = (textId, num) => {
  const catalog = i18n[LANG];
  const message = catalog[textId];
  if (!message) {
    console.warn(`invalid i18n: ${textId}`);
    return textId;
  }
  if (num !== undefined && message.length === 2) {
    return message[num === 1 ? 0 : 1].replace("NUM", num);
  } else if (num !== undefined) {
    return message.replace("VAR", num);
  }
  return message;
};

/**
 * Get duration as text from elapsed seconds
 */
export const getDuration = (seconds, minimal = false) => {
  if (seconds < 60) {
    return minimal ? seconds.toFixed(1) : _("seconds", seconds.toFixed(1));
  }
  let minutes = Math.floor(seconds / 60);
  seconds = Math.round(seconds % (minutes * 60))
    .toString()
    .padStart(2, "0");
  if (minutes < 60) {
    return `${minutes}:${seconds} ${minimal ? "" : _("minutes")}`;
  }
  let hours = Math.floor(minutes / 60);
  minutes = (minutes % (hours * 60)).toString().padStart(2, "0");
  if (minimal) {
    hours = hours.toString().padStart(2, "0");
  }
  return `${hours}:${minutes}:${seconds} ${minimal ? "" : _("hours")}`;
};
