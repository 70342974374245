import QRCode from "qrcode-svg";
import { aesGcmDecrypt, aesGcmEncrypt } from "./include/crypto";
import { pushEvent } from "./include/event";
import { getDuration, keyboardLayouts, LANG, _ } from "./include/language";
import {
  closeModal,
  getModalName,
  isModalOpen,
  openModal,
} from "./include/modals";
import { isHardMode } from "./include/ui";

(async () => {
  const $ = (id) => document.getElementById(id);

  let settings = {
    slug: null,
    puzzle_name: "word.rodeo",
    puzzle: null,
    use_timer: true,
    result_minimal: false,
    award_failed: false,
    share_url: null,
    emoji: null,
    countdown: null,
    dict: null,
    dict_exclusive: false,
    stats: null,
    save_progress: true,
  };
  if ($("settings")) {
    settings = Object.assign(settings, JSON.parse($("settings").innerText));
  }

  const SITE_TITLE = document.title;

  const STATUS_UNSOLVED = "unsolved";
  const STATUS_SOLVED = "solved";
  const STATUS_FAILED = "failed";

  window.gameLoaded = false;
  let solution = null;
  let status = STATUS_UNSOLVED;
  let extraLife = false;
  let awardText = "";

  // How many guesses are allowed?
  let guessCount = 6;
  // How many letters in the solution?
  let solutionLength = 0;
  // How many times each letter is in the solution
  let solutionLetters = {};
  // collect statistics to show the player for sharing
  let stats = {
    start: null,
    end: null,
    deleted: 0,
    invalid: 0,
  };
  const tileStatus = {};

  let keyboardLayout = "default";
  let dictionary = null;
  let dictionaryPromise = null;

  let currentGameId = null;

  const loadGame = (options) => {
    window.gameLoaded = true;
    solution = options.word;
    solutionLength = solution.length;
    solutionLetters = countLetters(solution);
    guessCount = options.guesses;
    keyboardLayout = options.kbd;
    awardText = options.award;

    const language = keyboardLayouts[keyboardLayout];

    if (options.dict && language.hasDictionary) {
      dictionaryPromise = loadDictionary(keyboardLayout, solutionLength);
    } else {
      dictionary = null;
    }

    const hintEl = $("hint");
    if (!hintEl.innerText) {
      hintEl.innerText = options.hint;
    }
    if (options.hint) {
      hintEl.classList.remove("hidden");
    } else {
      hintEl.classList.add("hidden");
    }

    buildBoard();
    buildKeyboard(language.layout);

    if (!navigator.userAgent.includes("Firefox")) {
      console.info("Applying center safe workaround.");
      const boardEl = $("board");
      const boardParent = boardEl.parentNode;
      boardParent.classList.remove("items-center-safe");
      // Only firefox supports align-items: safe center;
      // And we can't test for actual support because Chrome lies to us
      // with CSS.supports('align-items', 'safe center')
      if (boardEl.scrollHeight > boardParent.clientHeight) {
        boardParent.classList.remove("items-center");
        boardParent.classList.add("items-start");
      } else {
        boardParent.classList.remove("items-start");
        boardParent.classList.add("items-center");
      }
    }

    document.querySelectorAll("[data-loaded]").forEach((el) => {
      if (el.dataset.loaded === "hide") {
        el.style.display = "none";
      }
      if (el.dataset.loaded === "show") {
        el.style.display = "";
      }
    });
    document.querySelectorAll("a[data-setlang]").forEach((el) => {
      if (settings.puzzle) {
        return;
      }
      let url = `#${currentGameId}`;
      if (el.dataset.setlang === "en") {
        el.href = `/${url}`;
      } else {
        el.href = `/${el.dataset.setlang}/${url}`;
      }
    });
  };

  const keyboardEl = $("keyboard");

  /**
   * Generate the DOM required for the keyboard.
   */
  const buildKeyboard = (layout) => {
    keyboardEl.innerHTML = "";
    layout.forEach((row) => {
      const rowEl = document.createElement("div");
      rowEl.classList.add("key-row");
      row.forEach((key) => {
        if (key === "" || key === " ") {
          const spaceEl = document.createElement("div");
          spaceEl.tabIndex = -1;
          spaceEl.classList.add(key === "" ? "key-spacer" : "key-blank");
          rowEl.appendChild(spaceEl);
          return;
        }
        const keyEl = document.createElement("button");
        keyEl.classList.add("key");
        if (key === "enter" || key === "delete") {
          keyEl.classList.add("key-button");
        }
        if (key === "ß") {
          keyEl.classList.add("!lowercase");
        }
        if (key === "delete") {
          keyEl.title = "Delete";
          keyEl.innerHTML =
            '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"></path></svg>';
        } else {
          keyEl.textContent = key;
        }
        keyEl.dataset.key = key;
        rowEl.appendChild(keyEl);
      });
      keyboardEl.appendChild(rowEl);
    });
  };

  /**
   * Generate the board DOM.
   *
   * @param {Boolean} extra Add a new row of guesses.
   */
  const buildBoard = (extra) => {
    const boardEl = $("board");
    const addRow = () => {
      for (let j = 0; j < solutionLength; j++) {
        const tileEl = document.createElement("div");
        tileEl.classList.add("tile", "empty");
        tileEl.style.setProperty("--letter", j);
        boardEl.appendChild(tileEl);
      }
    };
    if (extra) {
      addRow();
      return;
    }

    boardEl.innerHTML = "";
    boardEl.style.setProperty("--wordlength", solutionLength);
    boardEl.style.setProperty("--guesscount", guessCount);
    boardEl.style.width = `${solutionLength * 72}px`;
    boardEl.style.gridTemplateRows = `repeat(${guessCount}, 1fr)`;
    boardEl.style.gridTemplateColumns = `repeat(${solutionLength}, 1fr)`;

    for (let i = 0; i < guessCount; i++) {
      addRow();
    }
  };

  let currentRow = 0;
  let currentLetter = 0;

  const getTile = (row, letter) => {
    const tileIndex = row * solutionLength + letter + 1;
    return document.querySelector(`#board .tile:nth-child(${tileIndex})`);
  };
  const getKey = (letter) => {
    return keyboardEl.querySelector(`[data-key="${letter}"]`);
  };
  const countLetters = (word) => {
    const counter = {};
    for (let i = 0; i < word.length; i++) {
      const letter = word[i];
      if (counter[letter]) {
        counter[letter] += 1;
      } else {
        counter[letter] = 1;
      }
    }
    return counter;
  };

  const saveState = () => {
    if (!stats.start) {
      // Record puzzle start time
      stats.start = Math.round(new Date().getTime() / 100) / 10;
      postStats({ type: "attempt" });
      pushEvent("First Letter");
    }

    if (currentGameId === null || settings.save_progress === false) {
      // Don't save any progress
      // But only after setting the start time
      return;
    }

    const tiles = [...document.querySelectorAll("#board .tile")].map(
      (tileEl) => ({
        letter: tileEl.textContent,
        status: tileEl.className,
      })
    );

    const keys = [...document.querySelectorAll("#keyboard [data-key]")]
      .filter((keyEl) => keyEl.dataset.key.length === 1)
      .map((keyEl) => ({
        letter: keyEl.dataset.key,
        status: keyEl.className,
      }));

    const state = {
      game: settings.slug,
      status,
      currentRow,
      currentLetter,
      tiles,
      keys,
      extraLife,
      stats,
    };

    if (settings.highscore) {
      state.previousStatID = previousStatID;
      state.highscoreSubmitted = highscoreSubmitted;
    }

    localStorage.setItem(currentGameId, JSON.stringify(state));
  };
  const loadState = () => {
    const data = localStorage.getItem(currentGameId);
    if (data) {
      const state = JSON.parse(data);
      status = state.status;
      currentRow = state.currentRow;
      currentLetter = state.currentLetter;
      extraLife = !!state.extraLife;
      stats = state.stats || {};
      if (extraLife) {
        buildBoard(true);
      }

      if (settings.highscore) {
        previousStatID = state.previousStatID || null;
        highscoreSubmitted = !!state.highscoreSubmitted;
        if (highscoreSubmitted) {
          $("highscore-submit").classList.add("hidden");
          $("highscore-done").classList.remove("hidden");
        }
      }

      // Restore tiles and keyboard state
      [...document.querySelectorAll("#board .tile")].forEach((tileEl, i) => {
        if (!state.tiles[i]) return;
        tileEl.innerText = state.tiles[i].letter;
        tileEl.className = state.tiles[i].status;

        const status = state.tiles[i].status;
        if (status.includes("correct") || status.includes("present")) {
          tileStatus[state.tiles[i].letter] = {
            correct: status.includes("correct"),
            present: status.includes("present"),
            position: i % solutionLength,
          };
        }
      });
      [...document.querySelectorAll("#keyboard [data-key]")]
        .filter((keyEl) => keyEl.dataset.key.length === 1)
        .forEach((keyEl, i) => {
          keyEl.innerText = state.keys[i].letter;
          keyEl.className = state.keys[i].status;
        });

      if (status !== STATUS_UNSOLVED) {
        setTimeout(() => {
          showResults();
        }, 1500);
      }
    } else {
      // Not played before, make sure to reset state
      status = STATUS_UNSOLVED;
      currentRow = 0;
      currentLetter = 0;
      extraLife = false;
      stats = {
        start: null,
        end: null,
        deleted: 0,
        invalid: 0,
      };
    }
    if (settings.highscore) {
      const form = $("highscore-submit");
      form.name.value = localStorage.getItem("highscore_name") || "";
      if (form.email) {
        form.email.value = localStorage.getItem("highscore_email") || "";
      }
    }
  };

  /**
   * Add a letter to the current row
   */
  const addLetter = (letter) => {
    if (status === STATUS_UNSOLVED && currentLetter < solutionLength) {
      const currentTile = getTile(currentRow, currentLetter);
      currentTile.classList.remove("empty");
      currentTile.classList.add("tbd");
      // toUpperCase turns it into "SS"
      if (letter === "ß") {
        currentTile.classList.add("!lowercase");
      }
      currentTile.textContent = letter;
      currentLetter++;
      saveState();
    }
  };

  /**
   * Remove the last letter from the current row
   */
  const deleteLetter = () => {
    if (status === STATUS_UNSOLVED && currentLetter !== 0) {
      const currentTile = getTile(currentRow, currentLetter - 1);
      currentTile.classList.add("empty");
      currentTile.classList.remove("tbd", "!lowercase");
      currentTile.textContent = "";
      currentLetter--;
      stats.deleted++;
      saveState();
    }
  };

  /**
   * Load the dictionary into memory
   *
   * Only loads words with the same length as the solution.
   */
  const loadDictionary = async (code, length, asArray = false) => {
    console.time("Load dictionary");
    dictionary = new Set();

    const requests = [];

    if (settings.dict) {
      requests.push(fetch(settings.dict));
    }

    if (!settings.dict_exclusive) {
      const url = `/dict/${
        code === "default" ? "english" : code
      }/${length}.txt`;
      requests.push(fetch(url));
    }

    const responses = await Promise.all(requests);
    let words = [];

    for (let response of responses) {
      if (!response.ok) {
        console.error(`Failed downloading dictionary: ${code}`);
        // Fallback, do not check for dictionary entries.
        // Could happen when the client is offline.
        dictionary = null;
        return;
      }
      const data = await response.text();
      words = words.concat(data.split("\n"));
    }

    if (asArray) {
      console.timeEnd("Load dictionary");
      return words;
    }

    dictionary = new Set(words);
    console.timeEnd("Load dictionary");
  };

  let previousStatID = null;
  let highscoreSubmitted = false;

  const postStats = async (data) => {
    if (!settings.stats) {
      return;
    }
    const body = new URLSearchParams();
    for (let key in data) {
      body.append(key, data[key]);
    }
    const response = await fetch(settings.stats, {
      method: "POST",
      body,
    });
    if (!response.ok) {
      console.error("Failed to post stats");
    }
    previousStatID = await response.text();
    saveState();
  };

  $("highscore-submit")?.addEventListener("submit", async (event) => {
    event.preventDefault();
    if (!settings.highscore || !previousStatID) {
      return;
    }
    const form = event.target;

    const body = new URLSearchParams();
    body.append("statistic", previousStatID);
    body.append("name", form.name.value);
    if (form.email) {
      body.append("email", form.email.value);
    }
    // Game as a multiline string
    const game = [...document.querySelectorAll("#board .tile")].reduce(
      (text, tileEl, i) =>
        text +
        tileEl.textContent +
        ((i + 1) % solutionLength === 0 ? "\n" : ""),
      ""
    );
    body.append("game", game.trim());

    const response = await fetch(settings.highscore, {
      method: "POST",
      body,
    });
    if (!response.ok) {
      const text = await response.text();
      showToast(text || _("err_highscore"));
    } else {
      highscoreSubmitted = true;
      saveState();
      localStorage.setItem("highscore_name", form.name.value);
      if (form.email) {
        localStorage.setItem("highscore_email", form.email.value);
      }
      form.classList.add("hidden");
      $("highscore-done").classList.remove("hidden");
    }
  });

  const shakeLine = (currentGuess) => {
    currentGuess.forEach(({ tile }) => {
      tile.classList.add("placed", "invalid");
    });
    setTimeout(() => {
      currentGuess.forEach(({ tile }) => {
        tile.classList.remove("invalid");
      });
    }, 1000);
  };

  /**
   * Check the current row for matches
   *
   * Does nothing if the row isn't complete
   */
  const checkWord = () => {
    const currentGuess = [];
    // Copy solution letters
    const guessLetters = Object.assign({}, solutionLetters);

    for (let i = 0; i < solutionLength; i++) {
      const tile = getTile(currentRow, i);
      const letter = tile.textContent.trim().toLowerCase();
      currentGuess.push({ tile, letter, status: "tbd" });
    }
    const guessedWord = currentGuess.map(({ letter }) => letter).join("");

    if (status === STATUS_UNSOLVED && currentLetter === solutionLength) {
      if (isHardMode()) {
        for (let [letter, { correct, present, position }] of Object.entries(
          tileStatus
        )) {
          if (correct && guessedWord[position] !== letter) {
            // This position should be a different letter
            showToast(_("hardmore_err_correct"));
            shakeLine(currentGuess);
            return;
          } else if (present && !guessedWord.includes(letter)) {
            // The solution must contains this letter
            showToast(_("hardmore_err_present", letter.toUpperCase()));
            shakeLine(currentGuess);
            return;
          }
        }
      }

      if (
        dictionary !== null &&
        guessedWord !== solution &&
        !dictionary.has(guessedWord)
      ) {
        // Check against dictionary
        if (dictionary.size === 0) {
          // Only show loading messages if a player
          // tries to check a word when we're not ready yet.
          showToast(_("dict_loading"));
          if (dictionaryPromise) {
            dictionaryPromise.then(() => {
              showToast(_("dict_ready"));
            });
          }
          return;
        }
        showToast(_("dict_error"));
        stats.invalid++; // only persisted with the next delete
        shakeLine(currentGuess);
        return;
      }

      // Check for exact matches
      currentGuess.forEach(({ tile, letter }, i) => {
        if (letter === solution[i]) {
          guessLetters[letter]--;
          currentGuess[i].status = "correct";
          tile.classList.remove("tbd");
          tile.classList.add("correct");
          const keyEl = getKey(letter);
          keyEl.classList.remove("absent", "present");
          keyEl.classList.add("correct");
        }
      });

      // Check partial matches
      currentGuess.forEach(({ tile, letter, status }, i) => {
        if (status === "tbd" && guessLetters[letter] > 0) {
          currentGuess[i].status = "present";
          guessLetters[letter]--;
          tile.classList.remove("tbd");
          tile.classList.add("present");
          const keyEl = getKey(letter);
          keyEl.classList.remove("absent");
          if (!keyEl.classList.contains("correct")) {
            keyEl.classList.add("present");
          }
        }
      });

      // Mark remaining letters as absent
      currentGuess.forEach(({ tile, letter, status }) => {
        if (status === "tbd") {
          tile.classList.remove("tbd");
          tile.classList.add("absent");
          const keyEl = getKey(letter);
          keyEl.classList.remove("absent");
          if (
            !keyEl.classList.contains("correct") &&
            !keyEl.classList.contains("present")
          ) {
            keyEl.classList.add("absent");
          }
        }
      });

      if (currentRow == 0) {
        pushEvent("First Guess");
      }
      currentLetter = 0;
      currentRow++;

      if (guessedWord === solution) {
        status = STATUS_SOLVED;
        stats.end = Math.round(new Date().getTime() / 100) / 10;
        postStats({
          type: "solved",
          guesses: currentRow,
          time_taken: stats.end - stats.start,
          word: solution,
          previous: previousStatID,
        });
        pushEvent("Solved", {
          wordlength: solutionLength,
          guessCount: currentRow,
        });
        setTimeout(() => {
          showToast(_("toast_solved"));
          currentGuess.forEach(({ tile }) => {
            tile.classList.add("winner");
          });
          setTimeout(() => {
            showResults();
          }, 1500);
        }, 80 * solutionLength + 500);
      } else if (
        currentRow === guessCount ||
        (extraLife && currentRow === guessCount + 1)
      ) {
        status = STATUS_FAILED;
        stats.end = Math.round(new Date().getTime() / 100) / 10;
        postStats({
          type: "failed",
          time_taken: stats.end - stats.start,
          previous: previousStatID,
        });
        pushEvent("Failed", { wordlength: solutionLength });
        setTimeout(() => {
          if (extraLife) {
            showToast(_("toast_failed_again"));
          } else {
            showToast(_("toast_failed"));
          }
          setTimeout(() => {
            showResults();
          }, 1500);
        }, 80 * solutionLength + 500);
      }
      saveState();
    } else if (status === STATUS_UNSOLVED) {
      showToast(_("toast_too_short"));
      shakeLine(currentGuess);
    }
  };

  const oneupEl = $("oneup");
  oneupEl?.addEventListener("click", () => {
    if (!!solution && status === STATUS_FAILED && !extraLife) {
      // Unlocked an extra life!
      pushEvent("Extra Life");
      showToast(_("toast_extralife"));

      status = STATUS_UNSOLVED;
      extraLife = true;
      saveState();
      loadState();

      closeModal();
    }
  });

  const startCountdown = () => {
    const countdown = $("countdown");
    if (!countdown) return;
    const target = new Date(settings.countdown);
    const tick = () => {
      const now = new Date();
      const remaining = Math.round((target - now) / 1000);
      countdown.innerText = getDuration(remaining, true);
      setTimeout(tick, 1000);
    };
    tick();
  };

  const decryptPrize = async (solved) => {
    const awardEl = $("award");
    const encrypted =
      solved || !awardEl.dataset.consolation
        ? awardEl.dataset.award
        : awardEl.dataset.consolation;
    const data = await aesGcmDecrypt(encrypted);
    awardEl.innerHTML = data;
  };

  const showResults = () => {
    const resultData = $("modal-result");
    resultData.classList.add(
      status === STATUS_SOLVED ? "result-solved" : "result-failed"
    );
    resultData.classList.remove(
      status === STATUS_FAILED ? "result-solved" : "result-failed"
    );
    if (extraLife || oneupEl === null) {
      resultData.classList.add("used-extralife");
      if (status === STATUS_FAILED && $("result-solution")) {
        $("result-solution").textContent = solution.toUpperCase();
      }
    } else {
      resultData.classList.remove("used-extralife");
    }

    $("result-attempts").innerText = extraLife
      ? `${currentRow - 1}+1`
      : currentRow;

    if (settings.countdown) {
      startCountdown();
    }
    if ($("result-duration")) {
      $("result-duration").innerText = getDuration(stats.end - stats.start);
    }

    const awardEl = $("award");
    const hasConsolation = !!awardEl.dataset.consolation;
    if (
      ((status === STATUS_SOLVED || settings.award_failed) && awardText) ||
      (hasConsolation && status === STATUS_FAILED)
    ) {
      awardEl.classList.remove("hidden");
      if (awardEl.dataset.award || awardEl.dataset.consolation) {
        decryptPrize(status === STATUS_SOLVED);
      } else {
        awardEl.innerText = awardText;
      }
    } else {
      awardEl.classList.add("hidden");
      awardEl.innerText = "";
    }

    let content = "";
    if (stats.deleted === 0) {
      content =
        status === STATUS_SOLVED ? _("deleted0_solved") : _("deleted0_failed");
    } else {
      content = _("deleted_letters", stats.deleted);
    }
    if (dictionary) {
      if (stats.invalid === 0 && stats.deleted === 0) {
        content += _("deleted0_invalid0");
      } else {
        content += _("invalid_words", stats.invalid);
      }
    } else if (stats.deleted !== 0) {
      content += ".";
    }

    $("result-stats").innerHTML = content;
    if (!settings.save_progress) {
      $("board").classList.add("hidden");
      $("modal-panel")
        .querySelector("[data-modal-close]")
        .classList.add("hidden");
    }
    openModal("result");
    if (settings.highscore) {
      $("highscore-submit").name.focus();
    }
  };

  const getSeason = () => {
    const month = new Date().getMonth();
    const day = new Date().getDate();
    let season;
    if (month === 1 && day == 14) {
      // hearts for valentine's day
      season = {
        name: "hearts",
        icon: "💛",
      };
    }
    if (month === 6 && day === 4) {
      // us independence day
      season = {
        name: "eagles",
        icon: "🇺🇸",
      };
    }
    if (month === 7 && day === 1) {
      // swiss national day
      season = {
        name: "cheese",
        icon: "🇨🇭",
      };
    }
    if (month === 9 && day == 31) {
      // halloween
      season = {
        name: "spooky",
        icon: "🎃",
      };
    }
    if ((month === 11 && day == 25) || (month === 11 && day == 26)) {
      // xmas
      season = {
        name: "xmas",
        icon: "🎅",
      };
    }
    return season;
  };

  /**
   * Compose a shareable text to copy
   */
  const getResultText = (emojiType) => {
    let text = ``;
    let attempts = `${currentRow}`;
    if (extraLife) {
      attempts = `${currentRow - 1}+1`;
    }
    if (settings.result_minimal) {
      text += settings.puzzle_name;
      if (status === STATUS_SOLVED) {
        text += ` ${attempts}/${guessCount}`;
      } else {
        text += ` X/${attempts}`;
      }
      if (settings.use_timer) {
        text += ` - ${getDuration(stats.end - stats.start)}`;
      }
    } else {
      if (status === STATUS_SOLVED) {
        text += _("puzzle_solved", `${attempts}/${guessCount}`);
        if (settings.use_timer) {
          text += _("puzzle_duration", getDuration(stats.end - stats.start));
        }
      } else {
        text += _("puzzle_failed", attempts);
      }
      text = text.replace("PUZZLE_NAME", settings.puzzle_name);
    }
    text += "\n";

    const isDark = document.documentElement.classList.contains("dark");
    const emojiMap = {
      default: {
        correct: "🟩",
        present: "🟨",
        absent: isDark ? "⬛" : "⬜",
      },
      hearts: {
        correct: "💚",
        present: "💛",
        absent: isDark ? "🖤" : "🤍",
      },
      eagles: {
        correct: "🇺🇸",
        present: "🗽",
        absent: "🦅",
      },
      cheese: {
        correct: "🇨🇭",
        present: "🧀",
        absent: "🍫",
      },
      spooky: {
        correct: "🎃",
        present: "🕯️",
        absent: "👻",
      },
      xmas: {
        correct: "🎅",
        present: "🎄",
        absent: "🦌",
      },
    };
    if (settings.emoji) {
      emojiMap.default = Object.assign(emojiMap.default, settings.emoji);
    }
    let emojis = emojiMap[emojiType];
    if (!emojis) emojis = emojiMap.default;

    [...document.querySelectorAll("#board .tile")].forEach((tileEl, i) => {
      if (tileEl.classList.contains("correct")) {
        text += emojis.correct;
      } else if (tileEl.classList.contains("present")) {
        text += emojis.present;
      } else if (tileEl.classList.contains("absent")) {
        text += emojis.absent;
      }

      if ((i + 1) % solutionLength === 0) {
        text += "\n";
      }
    });
    return text.trim();
  };

  keyboardEl.addEventListener("click", (event) => {
    let key = event.target.dataset.key;
    if (!key) {
      const closest = event.target.closest("[data-key]");
      if (!closest) {
        return;
      }
      key = closest.dataset.key;
    }
    if (key && key !== " ") {
      if (key === "delete") {
        deleteLetter();
      } else if (key === "enter") {
        checkWord();
      } else {
        addLetter(event.target.dataset.key);
      }
      event.target.blur();
    }
  });

  window.addEventListener("keydown", (event) => {
    if (!solution || event.repeat || event.ctrlKey) {
      return;
    }
    if (isModalOpen()) {
      if (event.key === "Escape") {
        if (getModalName() !== "result" || settings.save_progress) {
          closeModal();
        }
      }
      return;
    }
    const key = event.key;
    if (key === " ") {
      return;
    } else if (key === "Backspace") {
      deleteLetter();
    } else if (key === "Enter") {
      checkWord();
    } else {
      const keyEl = getKey(key);
      if (keyEl) {
        addLetter(key);
      }
    }
  });

  $("create-form")?.addEventListener("submit", async (event) => {
    event.preventDefault();
    const form = event.target;

    const shareLinkEl = $("share-link");
    const copyLinkEl = $("copy-link");

    const options = {
      word: form.solution.value.trim().toLowerCase(),
    };

    // Only add non-default options to the object
    // to keep the generated JSON and thus the URL small
    const guessValue = parseInt(form.guesscount.value, 10);
    if (guessValue !== 6) {
      options.guesses = guessValue;
    }
    if (form.keyboard.value !== "default") {
      options.kbd = form.keyboard.value;
    }
    const hintValue = form.hint.value.trim().substring(0, 140);
    if (hintValue) {
      options.hint = hintValue;
    }
    const awardValue = form.award.value.trim().substring(0, 140);
    if (awardValue) {
      options.award = awardValue;
    }
    if (form.dictionary.checked) {
      options.dict = true;
    }

    const selectedLayout = keyboardLayouts[options.kbd || "default"];
    if (options.dict && !selectedLayout.hasDictionary) {
      showToast(_("toast_nodict"));
      return;
    }

    pushEvent("Created", {
      wordlength: options.word.length,
      guessCount: options.guesses || 6,
      keyboard: options.kbd || "default",
      dictionary: !!options.dict,
      hasHint: !!options.hint,
      hasAward: !!options.award,
    });

    const encrypted = await aesGcmEncrypt(JSON.stringify(options));
    const shareLink = `${location.protocol}//${location.host}${location.pathname}?utm_source=share#${encrypted}`;
    const qrLink = `${location.protocol}//${location.host}${location.pathname}?utm_source=qrcode#${encrypted}`;

    const qrData = new QRCode({
      content: qrLink,
      padding: 1,
      ecl: "M",
      background: "#f1f5f9",
    }).svg();
    $("qrcode-image").src = `data:image/svg+xml;base64,${btoa(qrData)}`;

    form.classList.add("hidden");
    shareLinkEl.href = shareLink;
    copyLinkEl.href = shareLink;
    $("created-share").classList.remove("hidden");
  });

  $("create-random")?.addEventListener("click", async () => {
    const lang = LANG === "de" ? "german" : "english";
    const dict = await loadDictionary(lang, "5easy", true);
    const word = dict[Math.floor(Math.random() * dict.length)];
    const options = {
      dict: true,
      word,
    };
    if (LANG === "de") {
      options.kbd = "german";
    }

    pushEvent("Play Random");

    const encrypted = await aesGcmEncrypt(JSON.stringify(options));
    const link = `${location.protocol}//${location.host}${location.pathname}?utm_source=share#${encrypted}`;

    window.location = link;
  });

  const resetForm = () => {
    const form = $("create-form");
    form.solution.value = "";
    form.hint.value = "";
    form.award.value = "";
    form.classList.remove("hidden");
    $("created-share").classList.add("hidden");
    $("created-share-qr").classList.add("hidden");
  };
  $("create-more")?.addEventListener("click", () => resetForm());

  $("qrcode-image")?.addEventListener("click", (event) => {
    if (event.target.src) {
      const newWindow = window.open();
      const data =
        `<!DOCTYPE html><title>QR Code</title>` +
        `<img src="${event.target.src}" style="object-fit:contain;width:100%;height:100%;" />`;
      newWindow.document.body.innerHTML = data;
    }
  });

  // Validate the solution input
  const formHelp = $("solution-help");
  $("form-solution")?.addEventListener("blur", (event) => {
    event.target.classList.add(
      "invalid:text-red-900",
      "invalid:focus:ring-red-500",
      "invalid:border-red-500",
      "invalid:focus:border-red-500"
    );
  });

  $("form-solution")?.addEventListener("input", (event) => {
    const input = event.target;

    const wordLetters = countLetters(input.value);

    if (input.validity.patternMismatch) {
      if (input.value.includes(" ")) {
        formHelp.innerText = _("err_nospaces");
      } else {
        formHelp.innerText = _("err_pattern");
      }
    } else if (input.validity.tooShort) {
      formHelp.innerText = _("err_short");
    } else if (input.value.length > 10) {
      formHelp.innerText = _("err_long");
    } else if (input.validity.valid) {
      formHelp.classList.remove("text-red-600");
      formHelp.innerText = _("good_puzzle");
      const dupes = Object.values(wordLetters).filter((count) => count > 1);
      if (dupes.length > 1) {
        formHelp.innerText = _("err_dupe");
      }
      Object.entries(wordLetters).forEach(([letter, count]) => {
        if (count >= input.value.length * 0.8) {
          formHelp.innerText = letter.toUpperCase().repeat(20) + "!";
        } else if (count > 3) {
          formHelp.innerText = _("err_rand");
        }
      });
    }
    if (!input.validity.valid) {
      formHelp.classList.add("text-red-600");
    }
  });
  $("form-guesscount")?.addEventListener("input", (event) => {
    const help = $("guesscount-help");
    const input = event.target;
    if (input.value > 9) {
      help.innerText = _("guess_many");
    } else if (input.value < 5) {
      help.innerText = _("guess_few");
    } else {
      help.innerText = _("guess_help");
    }
  });

  const showToast = (text, time) => {
    const container = $("toast-container");
    const toast = document.createElement("div");
    toast.classList.add(
      "toast",
      "ease-out",
      "duration-300",
      "transition",
      "translate-y-2",
      "opacity-0"
    );
    toast.innerText = text;
    container.appendChild(toast);
    setTimeout(() => {
      toast.classList.remove("translate-y-2", "opacity-0");
      toast.classList.add("translate-y-0", "opacity-100");
    }, 10);
    setTimeout(() => {
      toast.classList.remove("ease-out", "duration-300", "translate-y-0");
      toast.classList.add("ease-in", "duration-100");
      setTimeout(() => {
        toast.classList.remove("opacity-100");
        toast.classList.add("opacity-0");
      }, 10);
      setTimeout(() => {
        container.removeChild(toast);
      }, 110);
    }, time || 3500);
  };
  window.showToast = showToast;

  $("form-keyboard")?.addEventListener("change", (event) => {
    const layout = keyboardLayouts[event.target.value];
    if (layout) {
      $("form-solution").pattern = layout.pattern;
    }
  });

  if (navigator.share) {
    $("share-link")?.classList.remove("!hidden");
    $("share-result")?.classList.remove("!hidden");
    $("share-link")?.addEventListener("click", (event) => {
      event.preventDefault();
      const anchor = event.target.closest("a");
      pushEvent("Share", { method: "Navigator" });
      navigator.share({
        title: SITE_TITLE,
        text: _("share_text"),
        url: anchor.href,
      });
    });

    $("share-result")?.addEventListener("click", () => {
      pushEvent("Share Result", { method: "Navigator" });
      const currentLink = `${location.protocol}//${location.host}${location.pathname}?utm_source=result${location.hash}`;
      let text = getResultText();
      let url = settings.share_url || currentLink;
      if (!url.match(/^https?:\/\//)) {
        text += "\n" + url;
        url = undefined;
      }
      navigator.share({
        title: SITE_TITLE,
        text,
        url,
      });
    });
  }

  $("copy-link")?.addEventListener("click", (event) => {
    event.preventDefault();
    const anchor = event.target.closest("a");
    pushEvent("Share", { method: "Clipboard" });
    navigator.clipboard.writeText(settings.share_url || anchor.href);

    const textNode = anchor.querySelector("span");
    textNode.innerText = _("share_copied");
    setTimeout(() => {
      textNode.innerText = _("share_copy");
    }, 750);
  });

  const copyResult = (isSeasonal = false) => {
    return (event) => {
      const button = event.target.closest("button");
      pushEvent("Share Result", { method: "Clipboard", seasonal: isSeasonal });

      const currentLink =
        settings.share_url ||
        `${location.protocol}//${location.host}${location.pathname}?utm_source=result${location.hash}`;

      let season = "default";
      if (isSeasonal) {
        const seasonData = getSeason();
        if (seasonData) {
          season = seasonData.name;
        }
      }

      const text = getResultText(season) + `\n\n${currentLink}\n`;
      navigator.clipboard.writeText(text);

      if (isSeasonal) {
        showToast(_("toast_share_copied"));
      } else {
        const textNode = button.querySelector("span");
        textNode.innerText = _("share_copied");
        setTimeout(() => {
          textNode.innerHTML = _("copy_result");
        }, 750);
      }
    };
  };

  $("copy-result")?.addEventListener("click", copyResult());
  const seasonButton = $("copy-result-season");
  seasonButton?.addEventListener("click", copyResult(true));
  if (seasonButton) {
    const seasonData = getSeason();
    if (seasonData) {
      seasonButton.classList.remove("!hidden");
      seasonButton.innerText = seasonData.icon;
    }
  }

  $("show-qrcode")?.addEventListener("click", () => {
    $("created-share-qr").classList.remove("hidden");
  });

  $("hint-unblur")?.addEventListener("click", (e) => {
    e.target.parentNode.remove();
    const hintEl = $("hint");
    hintEl.classList.remove("blur");
  });

  document.querySelectorAll("[data-setlang]").forEach((el) => {
    el.addEventListener("click", () => {
      localStorage.language = el.dataset.setlang;
    });
  });

  const loadHash = async () => {
    let hash;
    if (settings.puzzle && !location.search.includes("?preview")) {
      if (currentGameId) {
        // Skip reloading pre-defined game
        return;
      }
      hash = settings.puzzle;
    } else if (window.location.hash) {
      hash = window.location.hash.slice(1);
    }
    if (!hash) {
      return false;
    }

    let options = {};
    try {
      options = JSON.parse(atob(hash));
    } catch (e) {
      // Encrypted hash found
      const decrypted = await aesGcmDecrypt(hash);
      options = JSON.parse(decrypted);
    }
    if (options.word) {
      currentGameId = hash;
      loadGame({
        word: options.word,
        guesses: options.guesses || 6,
        kbd: options.kbd || "default",
        hint: options.hint || "",
        award: options.award || "",
        dict: !!options.dict,
      });
      loadState();
      return true;
    }

    return false;
  };

  window.addEventListener("hashchange", async () => {
    try {
      if (await loadHash()) {
        closeModal();
      }
    } catch (e) {}
  });

  try {
    let loaded;
    try {
      loaded = await loadHash();
    } catch (e) {
      console.error("Failed to load puzzle. Invalid URL?", e);
      document.querySelector("#board .js-error")?.classList.remove("hidden");
    }
    if ($("modal-language")) {
      if (
        !localStorage.language &&
        !navigator.language.match(LANG) &&
        /(de|fr|en|es)/i.test(navigator.language)
      ) {
        // We think you may prefer a translated version of the interface
        openModal("language");
        return;
      }
    }
    if (loaded === true) {
      if (localStorage["iknowhowtoplay"] !== "yes") {
        openModal("help");
      }
      return;
    } else if (loaded === false && $("modal-create")) {
      // Language seems to match
      // No hash, or invalid hash, so show the form.
      openModal("create");
    }
  } catch (e) {}
})();
